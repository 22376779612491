import {
  Alert,
  Avatar,
  Button,
  Container,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useSignInMutation } from "@/stores/api";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  Link as RRLink,
  useParams,
} from "react-router-dom";
import useAuthentication from "@/hooks/useAuthentication";

export default function SignIn() {
  const { code, ...rest } = useParams();

  const [signIn, { error }] = useSignInMutation();
  const { token } = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    signIn({
      email: data.get("email")?.toString(),
      password: data.get("password")?.toString(),
    });
  };
  useEffect(() => {
    if (token) {
      navigate(from, { replace: true });
    }
  }, [token, from, navigate]);

  return (
    <Container component="main" maxWidth="xs" sx={{ width: "100%" }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h6">
          Connexion
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {/* @ts-ignore */}
          {error?.data && error?.data?.message && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {/* @ts-ignore */}
              {error?.data?.message}
            </Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse mail"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Code"
            type="password"
            defaultValue={code}
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Connexion
          </Button>
          <Grid container>
            {/* <Grid item xs>
                            <Link href="/authentication/forgot-password" variant="body2">
                                Mot de passe?
                            </Link>
                        </Grid> */}
            <Grid item>
              <Link
                component={RRLink}
                to={
                  code
                    ? `/authentication/signup/${code}`
                    : "/authentication/signup"
                }
                variant="body2"
              >
                {"Je crée mon compte"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { persistor, store, useAppDispatch, } from '@/stores'
import Layout from "./Layout";
import Account from "./screens/account";
import Home from "./screens/Home";
import SignIn from "./screens/authentication/signin";
import AuthenticationLayout from "./screens/authentication";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import Library from "./screens/library";
// import LatoRegular from './assets/fonts/Lato-Regular.ttf';
// import LatoBold from '@/assets/fonts/Lato-Bold.ttf';
// import LatoBoldItalic from '@/assets/fonts/Lato-BoldItalic.ttf';
// import LatoExtraBold from '@/assets/fonts/Lato-ExtraBold.ttf';
// import LatoExtraBoldItalic from '@/assets/fonts/Lato-ExtraBoldItalic.ttf';
// import LatoItalic from '@/assets/fonts/Lato-Italic.ttf';
// import LatoLight from '@/assets/fonts/Lato-Light.ttf';
// import LatoLightItalic from '@/assets/fonts/Lato-LightItalic.ttf';
// import LatoThin from '@/assets/fonts/Lato-Thin.ttf';
// import LatoThinItalic from '@/assets/fonts/Lato-ThinItalic.ttf';
import OpenSansBold from '@/assets/fonts/OpenSans-Bold.ttf';
import OpenSansBoldItalic from '@/assets/fonts/OpenSans-BoldItalic.ttf';
import OpenSansExtraBold from '@/assets/fonts/OpenSans-ExtraBold.ttf';
import OpenSansExtraBoldItalic from '@/assets/fonts/OpenSans-ExtraBoldItalic.ttf';
import OpenSansItalic from '@/assets/fonts/OpenSans-Italic.ttf';
import OpenSansLight from '@/assets/fonts/OpenSans-Light.ttf';
import OpenSansLightItalic from '@/assets/fonts/OpenSans-LightItalic.ttf';
import OpenSansRegular from './assets/fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '@/assets/fonts/OpenSans-SemiBold.ttf';
import OpenSansSemiBoldItalic from '@/assets/fonts/OpenSans-SemiBoldItalic.ttf';
import Publication from "./screens/publication";
import { api } from "./stores/api";
import ForgotPassword from "./screens/authentication/forgot-password";
import SignUp from "./screens/authentication/signup";
import QS from 'qs'
import { camelizeKeys } from "humps";
import ConfirmEmail from "./screens/authentication/confirm-email";
import ResetPassword from "./screens/authentication/reset-password";
import useAuthentication from "./hooks/useAuthentication";
import KiokCode from "./screens/kiosk-code";
import { merge } from "lodash";

const RequireAuth = ({ children }: { children: React.ReactElement }) => {
  const { token, } = useAuthentication()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const hashes = useMemo(() => {
    const h = camelizeKeys(QS.parse(location.hash, {
      ignoreQueryPrefix: true,
      delimiter: /[;,&#/?]/
    }))
    const s = camelizeKeys(QS.parse(location.search, {
      ignoreQueryPrefix: true,
      delimiter: /[;,&#/?]/
    }))
    return merge(h, s)
  }, [location]) as { [key: string]: any }

  useEffect(() => {

    if (hashes.confirmationToken) {
      navigate(`/authentication/confirm-email/${hashes.confirmationToken}`, { replace: true });
    }
    else if (hashes.recoverToken) {
      navigate(`/authentication/forgot-password/reset/${hashes.recoverToken}`, { replace: true });
    }
  }, [hashes, navigate])
  useEffect(() => {
    if (token) {
      dispatch(api.endpoints.profile.initiate())
    }
  }, [token, dispatch])

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/authentication/signin" state={{ from: location }} replace />;
  }

  return children;
}
export const PrimaryColor = "#009000"

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansBold}) format('truetype');
            font-weight: bold;
            font-style: normal;
        }
    
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansBoldItalic}) format('truetype');
            font-weight: bold;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansExtraBold}) format('truetype');
            font-weight: bolder;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansExtraBoldItalic}) format('truetype');
            font-weight: bolder;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansItalic}) format('truetype');
            font-weight: normal;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansLight}) format('truetype');
            font-weight: 300;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansLightItalic}) format('truetype');
            font-weight: 300;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansRegular}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansSemiBold}) format('truetype');
            font-weight: 600;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansSemiBoldItalic}) format('truetype');
            font-weight: 600;
            font-style: normal;
        }
      `,
    },
  },
  palette: {
    // mode: 'dark',
    mode: 'light',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: PrimaryColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#F7B47D'
    },
    // background: {
    //   // default: 'cyan',
    //   // paper: React.CSSProperties['color'],
    // },
    // background: {
    //   // main: React.CSSProperties['color'],
    //   main: ,
    // },
    // mutedLight: {
    //   main: '#c2c0c0',
    // },
    // mutedDark: {
    //   main: '#707070',
    // },
    // blackish: {
    //   main: '#000000',
    // },
    // blackish2: {
    //   main: '#323232',
    // },
    // whitish: {
    //   main: '#FFFFFF',
    // },
    // whitish2: {
    //   main: '#E6E6E6',
    // },
    // shadow: {
    //   main: '#6E7881',
    // },
    // shadowDark: {
    //   main: '#B1B1B1',
    // },
  },
});
function App() {
  const routes: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <RequireAuth><Home /></RequireAuth> },
        { path: "/access/:code", element: <KiokCode /> },
        { path: "/library", element: <RequireAuth><Library /></RequireAuth> },
        { path: "/account", element: <RequireAuth><Account /></RequireAuth> },
        {
          path: "/publications/:publicationId/",
          element: <RequireAuth><Publication /></RequireAuth>,
          children: [
            { path: "/publications/:publicationId/:issueId", element: <RequireAuth><Publication /></RequireAuth> },
          ]
        },
        { path: "*", element: <Home /> },
      ],
    },
    {
      path: "/authentication", element: <AuthenticationLayout />,
      children: [
        { index: true, element: <SignIn /> },
        { path: "/authentication/signin", element: <SignIn /> },
        {
          path: "/authentication/signin/",
          element: <SignIn />,
          children: [
            { path: "/authentication/signin/:code", element: <SignIn /> },
          ]
        },
        { path: "/authentication/signup", element: <SignUp /> },
        {
          path: "/authentication/signup/",
          element: <SignUp />,
          children: [
            { path: "/authentication/signup/:code", element: <SignUp /> },
          ]
        },
        // { path: "/authentication/signup/:code", element: <SignUp /> },
        { path: "/authentication/forgot-password", element: <ForgotPassword />, },
        { path: "/authentication/forgot-password/reset/:token", element: <ResetPassword /> },
        { path: "/authentication/confirm-email/:token", element: <ConfirmEmail /> },
      ]
    },
  ];
  let element = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}

export default (App);